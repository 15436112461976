import React from "react";

export default function AuthWrapper({ children, img = "/assets/banner.jpg"}) {
  return (
    <div className="w-screen  flex justify-center items-center py-20 relative px-10">
      <img
        src={img}
        alt="banner image"
        className="absolute inset-0 h-full w-full object-cover bg-center"
      />
      <div className="w-full h-full absolute inset-0 bg-primary/70" />
      <div className="w-full max-w-md space-y-8 bg-white relative mx-auto p-8">
        {children}
      </div>
    </div>
  );
}
