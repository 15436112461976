import AdminRoutes from "@routes";
import { Form, Modal, Result } from "antd";
import apiClient from "apiClient";
import Button from "components/base/Button";
import Input from "components/base/Input";
import Select from "components/base/Select";
import React, { useEffect, useReducer } from "react";

export default function CreateAppointment() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});

  async function getCategory() {
    try {
      let res = await apiClient({ method: "get", url: "/category" });
      let categories = res.data.data?.map((category) => ({
        label: category.categoryName,
        value: category.categoryId,
      }));
      dispatch({ categories });
    } catch (error) {
      console.log(error);
      Modal.error({ title: "An error occured." });
    }
  }

  useEffect(() => {
    let subscribed = false;

    if (!subscribed) {
      getCategory();
    }

    return () => {
      subscribed = true;
    };
  }, []);

  const createAppointment = async (appointment) => {
    dispatch({ creatingAppointment: true });
    try {
      let response = await apiClient({
        method: "post",
        data: appointment,
        url: "/appointment",
      });
      dispatch({ creatingAppointment: false });
      if (response.data.success) {
        dispatch({
          creatingAppointment: false,
          result: {
            type: "success",
            title: "Appointment Successfully created",
            message:
              "Thanks your contacting us. We will get back to you shortly.",
          },
        });
      } else {
        dispatch({
          creatingAppointment: false,
          result: {
            type: "error",
            title: "Error occured",
            message: response.data.message,
          },
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        creatingAppointment: false,
        result: {
          type: "error",
          title: "Error occured",
          message:
            "Sorry an error occured creating your appointment. Please try again later.",
        },
      });
    }
  };

  return (
    <div className="py-8 relative h-screen">
      <div className="w-full h-full absolute inset-0" />

      <div className="w-full max-w-xl mx-auto mt-10 p-4 space-y-8 relative">
        <h4 className="text-3xl font-display text-center font-bold">
          Create New Appointment
        </h4>

        {state.result ? (
          <Result
            status={state.result.type}
            title={state.result.title}
            subTitle={state.result.message}
            extra={[
              <Button type="primary" href={AdminRoutes.AppointmentPage.path}>
                Go Back
              </Button>,
            ]}
          />
        ) : (
          <Form
            layout="vertical"
            onFinish={createAppointment}
            requiredMark="optional"
          >
            <Form.Item
              label="Reason for contacting us"
              name="title"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Category"
              name="categoryId"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select
                placeholder="Choose a category"
                options={state.categories}
              />
            </Form.Item>
            <Form.Item
              label="Brief Summary"
              name="description"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={state.creatingAppointment}
                className="w-full"
              >
                Create Appointment
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}
