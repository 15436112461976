import React from "react";
import Button from "@base/Button";
import Form from "antd/lib/form";
import Input from "@base/Input";

export default function CreatePasswordPage() {
  return (
    <AuthWrapper>
      <div>
        <h1 className="font-semibold text-2xl font-display">Create Password</h1>
        <p>Enter your Login Password</p>
      </div>
      <Form name="login" layout="vertical" autoComplete="off">
        <Form.Item
          label="Password"
          name="password"
          requiredMark="optional"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: "email",
              message: "Invalid Email",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          requiredMark="optional"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: "email",
              message: "Invalid Email",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button htmlType="submit" className="w-full">
          Create Password
        </Button>
      </Form>
    </AuthWrapper>
  );
}
