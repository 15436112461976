import classnames from 'classnames'
import React from 'react'

export default function Header({children, className}) {
    const cn = classnames({
        'text-5xl font-display': true,
        [className]: true
    })
  return (
    <h2 data-aos="fade-up" className={cn}>{children}</h2>
  )
}
