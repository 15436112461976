import React from 'react'
import AntInput from "antd/lib/input";
import AntInputNumber from "antd/lib/input-number";

export default function Input(propes) {
  return <AntInput size='large' {...propes}/>
}

Input.Number = (propes) => {
    return <AntInputNumber size='large' {...propes} />
}
Input.TextArea = (propes) => {
    return <AntInput.TextArea size='large' {...propes} />
}
Input.Password = (propes) => {
    return <AntInput.Password size='large' {...propes} />
}