import React from "react";
import PageFooter from "./page_footer";
import PageNavbar from "./page_navbar";
import Head from "components/Head";

export default function Layout({ children, title, description}) {
  return (
    <div className="bg-background">
      <Head title={title} description={description} />
      <PageNavbar />
      {children}
      <PageFooter />
    </div>
  );
}
