import AOS from "aos";
import { useEffect } from "react";
import Pages from "@pages";
import { AuthContextProvider } from "@context";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <AuthContextProvider>
      <Pages />
    </AuthContextProvider>
  );
}

export default App;
