import classnames from "classnames";
import AntButton from "antd/lib/button";
import React from "react";

export default function Button({
  href,
  type = "primary",
  loading,
  ...rest
}) {

  return (
    <AntButton
    href={href}
    size="large"
    type={type}
    className="uppercase"
    loading={loading}
    {...rest}
    />
  );
}

Button.Secondary = ({ href, className, children, loading, ...rest}) => {
  let cn = classnames({
    [className]: true,
  });
  return (
    <AntButton
      href={href}
      size="large"
      className={cn}
      children={children}
      loading={loading}
      {...rest}
    />
  );
};
