import AuthWrapper from 'components/AuthWrapper'
import Button from 'components/base/Button'
import React from 'react'
import AuthRoutes from 'routes/auth_routes'

export default function AccountCreated() {
  return (
    <AuthWrapper>
        <div>
        <h2 className='font-display text-2xl font-bold'>Account Created</h2>
        <p>Your account has been successfully created. Visit your mail to verify your email</p>
        <Button href={AuthRoutes.LoginPage.path}>Go to login</Button>
        </div>
    </AuthWrapper>
  )
}
