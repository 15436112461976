import classNames from "classnames";
import React from "react";
import Link from "./base/Link";

export default function Logo({ className, light, path = "/" }) {
  const cn = classNames({
    "w-10 object-cover": true,
    [className]: true,
  });

  if (light) {
    return (
      <span className="font-display text-4xl font-bold">
        <Link to={path}>GP</Link>
      </span>
    );
  }
  return (
    <Link to={path}>
      <img src="/assets/logo.png" className={cn} alt="LOGO" />
    </Link>
  );
}
