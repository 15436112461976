import React from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";

export default function AdminLayout({ children }) {
  return (
    <div className="min-h-screen">
      <NavBar />
      <div className="pb-10">{children}</div>
      <Footer />
    </div>
  );
}
