import { Spin, Timeline } from "antd";
import apiClient from "apiClient";
import Button from "components/base/Button";
import moment from "moment";
import React, { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";

export default function ConsultAppointment() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, { appointment: {} });

  const params = useParams();
  async function getAppointments() {
    dispatch({ fetchingAppointment: true });
    try {
      let res = await apiClient({
        method: "get",
        url: `/appointment/${params.id}`,
      });
      dispatch({ appointment: res.data.data, fetchingAppointment: false });
    } catch (error) {
      console.log(error);
      dispatch({ fetchingAppointment: false, appointmentError: true });
    }
  }

  useEffect(() => {
    let subscribed = false;

    if (!subscribed) {
      getAppointments();
    }

    return () => {
      subscribed = true;
    };
  }, []);

  let appointment = state.appointment;
  return (
    <div>
      {state.fetchingAppointment && <Loader />}

      <div className="w-screen max-w-5xl mx-auto py-10 space-y-10 px-2">
        <div className="flex justify-between">
          <div>
            <h1 className="text-3xl font-display">
              {appointment.appointmentName}
            </h1>
            <h6>{appointment.appointmentDate}</h6>
            {appointment.category && (
              <div className="py-1 px-2 border border-primary w-max text-primary">
                {appointment.category?.categoryName}
              </div>
            )}
            <p>{appointment.appointmentDescription}</p>
          </div>
          <div>
            <div
              className={`py-1 px-2 ${
                appointment.appointmentDone ? "bg-primary" : "bg-yellow-500"
              } text-white w-max`}
            >
              {appointment.appointmentDone ? "Done" : "Pending"}
            </div>
          </div>
        </div>

        <div className="w-full mx-auto">
          <Timeline className="text-xl" mode="left">
            {appointment.activities?.map((activity, i) => {
              console.log(activity);
              return (
                <Timeline.Item
                  key={i}
                  label={moment(new Date(activity.createdAt)).format(
                    "ddd, MM yyyy HH:MM"
                  )}
                  className="text-xl"
                >
                  <h6 className="font-display text-xl">{activity.title}</h6>
                  <p className="text-base">{activity.description}</p>
                  {activity.link && (
                    <div className="flex flex-wrap gap-2 items-center">
                      <a href={activity.link} target="_blank">
                        {activity.link}
                      </a>
                      <Button>Copy Link</Button>
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        </div>
      </div>
    </div>
  );
}

const Loader = () => {
  return (
    <div className="w-full h-20 flex justify-center items-center">
      <Spin />
    </div>
  );
};
