import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Button from "components/base/Button";
import Link from "components/base/Link";
import AuthRoutes from "@routes/auth_routes";
import Logo from "components/Logo";
import { AnimatePresence, motion } from "framer-motion";
import { fadeIn } from "animations";

export default function PageNavbar() {
  const [show, setShow] = useState(false);

  const [isCmtModalVisible, setIsCmtModalVisible] = useState(false);


  const showCommentModal = () => {
    setIsCmtModalVisible(true);
  };

  const handleOk = () => {
   // setIsModalVisible(false);
    setIsCmtModalVisible(false);
  };



  const toggleShow = () => setShow(!show);
  let menus = [
    { path: "/#about", name: "About Us" },
    { path: "/#services", name: "Our Services" },
    { path: "/#mission", name: "Mission" },
    { path: AuthRoutes.ContactUs.path, name: "Contact Us" },
    
 
  ];
  return (

    
  

    <div className="sticky top-0 z-10">
      <div className="flex justify-between xl:px-40 px-8 h-12 items-center shadow bg-white">
        <div className="font-display uppercase font-bold text-lg">
          <Logo />
        </div>

       

        <section className="flex items-center gap-10">
          <div className="xl:flex items-center gap-10 hidden ">
            {menus.map((menu, i) => {
              return (
                <Link.NavLink key={i} to={menu.path} className="uppercase">
                  {menu.name}
                </Link.NavLink>
              );
            })}
            <div className="flex space-x-2">
            <Button      type="ghost" href={AuthRoutes.LoginPage.path}>
            Book Appointment
            </Button>
            <Button type="primary" href={AuthRoutes.LoginPage.path}>
            Login
            </Button>

            </div>
          </div>
          <div className="xl:hidden flex gap-4 items-center">
            <div className="hidden md:inline-flex">
              <Button type="primary" href={AuthRoutes.LoginPage.path} className="!uppercase">
                Login
              </Button>
            </div>
            <button onClick={toggleShow}>
              <FaBars />
            </button>
          </div>
        </section>
      </div>

      {show && (
        <AnimatePresence>

        <motion.section initial={fadeIn.hidden} animate={fadeIn.visible} className="h-screen inset-0 w-full shadow absolute p-4">
          <img
            src="/assets/banner.jpg"
            alt="banner image"
            className="absolute inset-0 h-full w-full object-cover bg-center"
          />
          <div className="w-full h-full absolute inset-0 bg-primary/70" />

          <div className="flex justify-end relative">
            <button onClick={toggleShow} className="text-white text-2xl">
              <FaTimes />
            </button>
          </div>

          <ul className="mt-10 space-y-4 relative">
            {menus.map((menu, i) => {
              return (
                <li key={i}>
                  <Link.NavLink onClick={toggleShow} className="!text-white/80 uppercase" to={menu.path}>
                    {menu.name}
                  </Link.NavLink>
                </li>
              );
            })}
            <li>
              <Button type="default" href={AuthRoutes.LoginPage.path} className="!uppercase">
                Login
              </Button>
            </li>
            <li>
              <Button type="ghost" href={AuthRoutes.LoginPage.path} className="!uppercase">
                Book Appointment
              </Button>
            </li>
          </ul>
        </motion.section>
        </AnimatePresence>
      )}
    </div>

    


  );
}
