
const CryptoJs = require('crypto-js');


var iv = CryptoJs.enc.Utf8.parse(process.env.REACT_APP_IV);
var key = CryptoJs.enc.Utf8.parse(process.env.REACT_APP_KEY);

export const encryptData = (text) => {
    return CryptoJs.AES.encrypt(text, key, { iv }).toString();
  };
 export const decryptData = (encrypted) => {
    return CryptoJs.AES.decrypt(encrypted, key, { iv }).toString(
        CryptoJs.enc.Utf8
    );
};


