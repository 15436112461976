import AdminRoutes from "@routes";
import { Result, Spin } from "antd";
import apiClient from "apiClient";
import Button from "components/base/Button";
import Link from "components/base/Link";
import moment from "moment";
import React, { useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";

export default function AppointmentPage() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});
  const history = useHistory();
  

  async function getAppointments() {
    dispatch({ fetchingAppointment: true });
    try {
      let res = await apiClient({ method: "get", url: "/appointment" });
      if (res.data.success) {
        dispatch({ appointments: res.data.data, fetchingAppointment: false });
      } else {
        history.push(`${AdminRoutes.ProfileSettings.path}?tab=${res.data.missingTab}`)
      }
    } catch (error) {
      console.log(error);
      dispatch({ fetchingAppointment: false, appointmentError: true });
    }
  }
  async function getPendingAppointments() {
    dispatch({ fetchingPendingAppointment: true });
    try {
      let res = await apiClient({ method: "get", url: "/pending-appointment" });
      dispatch({
        pendingAppointments: res.data.data,
        fetchingPendingAppointment: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({ fetchingPendingAppointment: false, pendingError: true });
    }
  }
  useEffect(() => {
    let subscribed = false;

    if (!subscribed) {
      getPendingAppointments();
      getAppointments();
    }

    return () => {
      subscribed = true;
    };
  }, []);

  const noAppointment = (state.pendingAppointments && state.pendingAppointments.length === 0) && (state.appointments && state.appointments.length === 0);
  return (
    <div className="px-2">
{ !noAppointment &&     <div className="w-full max-w-5xl mx-auto py-20 space-y-10">
        <div>
          <h2 className="text-2xl font-display">Current Appointment</h2>
          {state.fetchingPendingAppointment ? (
            <Loader />
          ) : state.pendingError ? (
            <Result
              title="Could not fetch appointments"
              status="error"
              subTitle="An error occured fetching your appointments"
            />
          ) : (
            <div>
              {state.pendingAppointments?.map((appointment) => {
                return (
                  <Link key={appointment} to={AdminRoutes.ConsultAppointment.routePath + '/' + appointment.appointmentId}>
                    <div className="bg-gray-100 hover:bg-gray-200 transition-all p-2 px-4 flex justify-between items-center">
                      <div>
                        <h4 className="font-semibold text-lg">
                          {appointment.appointmentName}
                        </h4>
                        <h5>{moment(appointment.appointmentDate).fromNow()}</h5>
                      </div>
                      <div>
                        <div className="py-1 px-2 bg-yellow-500 text-black">
                          Pending
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}

              {state.pendingAppointments &&
                state.pendingAppointments.length === 0 && (
                  <div className="flex justify-center flex-col items-center">
                    <h5 className="text-xl">You don't have a pending appointment. Create a new appointment below.</h5>
                  <Button href={AdminRoutes.CreateAppointment.path}>
                    Create An Appointment
                  </Button>
                  </div>
                )}
            </div>
          )}
        </div>
        <div>
          <h2 className="text-2xl font-display">Recent Appointment</h2>
          {state.fetchingAppointment ? (
            <Loader />
          ) : state.appointmentError ? (
            <Result
              title="Could not fetch appointments"
              status="error"
              subTitle="An error occured fetching your appointments"
            />
          ) : (
            <div className="w-full flex flex-col space-y-4">
              {state.appointments?.map((appointment) => {
                return (
                  
                  <Link key={appointment} to={AdminRoutes.ConsultAppointment.routePath + '/' + appointment.appointmentId}>
                    <div className="bg-gray-100 hover:bg-gray-200 transition-all p-2 px-4 flex justify-between items-center">
                      <div>
                        <h4 className="font-semibold text-lg">
                          {appointment.appointmentName}
                        </h4>
                        <h5>{moment(appointment.appointmentDate).fromNow()}</h5>
                      </div>
                      <div>
                        <div className="py-1 px-2 bg-green-500 text-black">
                          Done
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>}

      {noAppointment && (
        <div className="p-10 flex justify-center items-center w-full h-[80vh] text-center">
          <div className="space-y-4">
            <div>
              <h1 className="font-bold font-display text-4xl">
                Do you need help?
              </h1>
              <p>
                Do you need help? Create an appointment today. We are here for
                you
              </p>
            </div>
            <Button href={AdminRoutes.CreateAppointment.path}>
              Create An Appointment
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

const Loader = () => {
  return (
    <div className="w-full h-20 flex justify-center items-center">
      {" "}
      <Spin />{" "}
    </div>
  );
};
