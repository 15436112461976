import React from "react";
import Form from "antd/lib/form";
import AuthRoutes from "routes/auth_routes";
import Input from "components/base/Input";
import { Link } from "react-router-dom";
import Button from "components/base/Button";
import AuthWrapper from "components/AuthWrapper";
import { useAuth } from "@context";
import { Alert } from "antd";

export default function LoginPage() {
  const auth = useAuth().auth;
  return (
    <AuthWrapper>
      <div>
        <h1 className="font-semibold text-3xl font-display">
          Login to your account
        </h1>
        <p>Enter your credentials to login</p>
      </div>
     {auth.error && <Alert message={auth.error?.message} type="error" />}
      <Form name="login" layout="vertical" onFinish={auth.loginUser}>
        <Form.Item
          label="Email Address"
          name="emailAddress"
          requiredMark="optional"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          requiredMark="optional"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className="text-right">
          <Link
            type="text"
            className="w-full"
            to={AuthRoutes.ForgetPasswordPage.path}
          >
            Forget Password
          </Link>
        </Form.Item>
        <Form.Item>
        <Button loading={auth.loading} htmlType="submit" className="w-full">
          Login
        </Button>
        </Form.Item>

        <Button.Secondary
          href={AuthRoutes.SignUpPage.path}
          type="ghost"
          className="w-full"
        >
          Create Account
        </Button.Secondary>
      </Form>
    </AuthWrapper>
  );
}
