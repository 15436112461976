import { Result, Spin } from "antd";
import apiClient from "apiClient";
import AuthWrapper from "components/AuthWrapper";
import Button from "components/base/Button";
import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import AuthRoutes from "routes/auth_routes";

export default function VerifyEmail() {
  const reducer = (prevState, newState) => ({ ...prevState, ...newState });
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    error: null,
    success: null,
  });
  const params = useParams();
  const token = params.token;

  async function verifyEmail() {
    dispatch({ loading: true });
    try {
      let result = await apiClient({
        method: "get",
        url: `/verify-email/${token}`,
      });

      dispatch({loading: false})

      if (result.data.success) {
        dispatch({ success: "Account successfully verified" });
      } else {
        dispatch({ error: result.data.message });
      }
    } catch (error) {
      console.log(error);
      dispatch({ error: "An error occured", loading: false});
    }
  }

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <AuthWrapper>
      {state.loading && (
        <div className="flex justify-center items-center">
          <Spin />
        </div>
      
        
    )}

      {state.error && <Result title={state.error} status="error" />}
      {state.success && <Result title={state.success} status="success" subTitle='Your email has been verified successfully' />}

      {/* <div>
        <h2 className="font-display text-2xl font-bold">Email verified</h2>
        <p>Your email has been verified successfully</p>
        <Button href={AuthRoutes.LoginPage.path}>Go to login</Button>
      </div> */}
    </AuthWrapper>
  );
}
