import React from "react";
import Header from "../components/header";
import service8 from "../services/exams-assistance.png";
import service7 from "../services/national-international-admissions.jpg";
import service6 from "../services/advertisement.jpeg";
import service5 from "../services/digital-marketing.jpg";
import service3 from "../services/academic-counseling.jpg";
import service4 from "../services/career-counseling.jpg";
import service2 from "../services/project-writing-assistance.jpeg";
import service1 from "../services/data-analysis.jpeg";

import vid1 from "../services/vids/vid1.mp4";
import vid2 from "../services/vids/vid2.mp4";
import vid3 from "../services/vids/vid3.mp4";

export default function HomePage() {
  const vision = [
    "To empower and encourage youths to be independent.",
    "To help in the alleviation of poverty in Africa especially in Cameroon through our different opportunities.",
    "To help small businesses and startups facing challenges to grow.",
    "To promote self-supporting, good governance, self-propagating organization that is committed to help mankind especially vulnerable, needy, women and young girls.",
    "To raise awareness in youth development through capacity building.",
    "To encourage the need for Education and help more youths get the best education they need.",
    "To encourage skills training and self-employment among our youths and graduates.",
    "Transformation of the mentality/mindset of Cameroonians and Africa through seminars and online trainings.",
    "To help individuals or companies who want to start a business in Cameroon go through the process easily.",
    "To help startups, individual businesses and companies grow their business over the internet and train them on how to create amazing contents for their business.",
    "To provide a reliable, trusted and effective means of advertisement.",
    "To create more jobs.",
  ];

  const services = [
    "DATA ANALYSIS",
    "PROJECT WRITING ASSISTANCE",
    "ACADEMIC COUNSELING",
    "BUSINESS/CAREER COUNSELING",
    "DIGITAL MARKETING",
    "ADVERTISEMENT",
    "NATIONAL AND INTERNATIONAL ADMISSIONS ASSISTANCE",
    "ASSISTANCE ON NATIONAL AND INTERNATIONAL EXAMS",
  ];
  const servicesDescp = [
    "Many struggle on how to design their questionnaires, get samples, design experiments, present their research data, discuss results and make conclusions, GIP got you covered",
    "Writing Research Projects, be it for academic or professional purposes could be overwhelming. With the help of GIP, you can have professionals get the whole process done with excellence and on time",
    "Through our student coaching and academic counseling programs, GIP helps students build successful study habits and improve their academic performance through self discovery and development. We believe that students can only be better when they understand and utilize their potentials",
    "Many businesses operate below capacity. Also, many talented individuals settle for jobs which are below their potentials and qualifications. Through business and career counseling, GIP helps its clients to thrive and create meaningful impact that results in the growth of their businesses and careers",
    "With our team of professionals, we provide one on one as well as group training sessions on content creation, social media marketing, digital marketing and other soft skills that will permit you to grow your brand and generate leads over the internet",
    "Every business needs good advertisement, and G.I.P makes the job easy for everyone.",
    "We provide admission assistance to different programs and universities both home and abroad. We are there to direct you on which program, University or country of study is best for you and help you get admission in the program and University of your choice. We also provide you with the right information about the city and country you wish to study in and we assist you once you arrived the there.",
    "With smart experts in our team who have written and have excellent scores in most common national and international exams, we provide one on one assistance on national and international exams like GCE A/L, TOEFL, IELTS, NCLEX, CBT, GRE, and SAT",
  ];

  const servImage = [
    service1,
    service2,
    service3,
    service4,
    service5,
    service6,
    service7,
    service8,
  ];
  return (
    <div>
      <div className="h-screen relative">
        <img
          className="h-full w-full object-cover absolute indent-0"
          src="/assets/banner.jpg"
          alt="/assets/banner.jpg"
        />
        <div className="bg-gradient-to-tr from-black absolute inset-0" />

        <div className="relative h-full flex flex-col items-center xl:items-start justify-center text-center xl:text-left w-full xl:w-4/5 gap-20  mx-auto">
          <div className="relative flex flex-col justify-center px-8  gap-4">
            <h1
              data-aos="fade-up"
              data-aos-duration="400"
              className="xl:text-8xl text-6xl font-display text-white leading-[110px] font-bold"
            >
              <span>Gracious</span>
            </h1>
            <h1
              data-aos="fade-up"
              data-aos-duration="500"
              className="xl:text-8xl text-6xl font-display text-white leading-[110px] font-bold"
            >
              <span>Innovation</span> <br />
            </h1>
            <h1
              data-aos="fade-up"
              data-aos-duration="800"
              className="xl:text-8xl text-6xl font-display text-white leading-[110px] font-bold"
            >
              <span>Pivot</span>
            </h1>
            <span
              data-aos="fade-up"
              data-aos-duration="1000"
              className="italic text-3xl  font-display text-white"
            >
              Hardwork, Service, Loyalty, and Patience
            </span>
          </div>

          <div data-aos="fade-in" data-aos-duration="2000">
          </div>
        </div>
      </div>

      <div className="py-20" id="about">
        <div className="flex px-8 space-y-8 flex-col xl:flex-row lg:space-y-0  max-w-7xl mx-auto gap-8">
          <div className="flex-1">
            <Header className="text-center">Who we are</Header>
            <p className="text-xl mt-8">
              GIP is a company with well-trained personnels in different areas
              of life with the aim of helping many attain success in their
              academic, career, business, and other areas of their lives through
              education, career, and business counselling and assistances.
            </p>
            <p className="text-xl">
              We believe every individual has unique and amazing potentials that
              must be expressed and amplified to produce excellent and
              distinctive results. GIP LIMITED therefore aims at being a
              catalyst of personal growth and empowerment.
            </p>
          </div>
          <div className="flex-1">
            <img src="/assets/whoweare.jpg" className="w-full" />
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-8 space-y-8 lg:space-y-0  lg:grid grid-cols-3 gap-8 mt-20">
          <div
            data-aos="fade-up"
            className="w-full bg-white border border-gray-300"
          >
            <video
              className="w-full"
              height="240"
              controls
              src={vid1}
              type="video/mp4"
              autoplay
              muted
            ></video>
            <div className="p-6">
              <h5 className="text-3xl font-display">Customer's Satisfaction</h5>
              <p className=" text-base mt-4">
                Our customer’s success is our success, so we will always make
                sure our customers are well pleased.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="w-full bg-white border border-gray-300"
          >
            <video
              className="w-full"
              height="240"
              controls
              src={vid2}
              type="video/mp4"
              autoplay
              muted
            ></video>
            <div className="p-6">
              <h5 className="text-3xl font-display">We are Global</h5>
              <p className="text-base mt-4">
                We are a Worldwide Company, our services are not limited to just
                a country, we are global.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="w-full bg-white border border-gray-300"
          >
            <video
              className="w-full"
              height="240"
              controls
              src={vid3}
              type="video/mp4"
              autoplay
              muted
            ></video>
            <div className="p-6">
              <h5 className="text-3xl font-display">
                Technologically Up-to-date
              </h5>
              <p className="text-base mt-4">
                We use up-to-date technological knowhow to execute our projects
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-20" id="services">
        <Header className="text-center">Our Services</Header>
        <div className="flex px-8 space-y-8 lg:space-y-0  flex-wrap max-w-7xl mx-auto gap-8">
          {services.map((services, i, n) => {
            return (
              <div key={i} className="w-80 mx-auto">
                <div className="w-45">
                  <img
                    key={n}
                    src={servImage[i]}
                    className="w-full h-60 object-cover"
                  />
                </div>
                <h4 className="font-bold mt-2 text-base">{services}</h4>
                <p>{servicesDescp[i]}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="space-y-8 py-20 max-w-7xl mx-auto px-8">
        <h4 className="text-5xl text-center">Our Vision</h4>
        <ul className="lg:grid grid-cols-3 gap-4 space-y-4 lg:space-y-0">
          {vision.map((v, i) => (
            <li
              className="w-full p-4 border text-lg border-secondary bg-secondary/5"
              key={i}
              data-aos="fade-up"
              data-aos-delay={100 * i}
            >
              👉 {v}
            </li>
          ))}
        </ul>
      </div>

      <div
        data-aos="fade-in"
        data-aos-duration="1000"
        id="mission"
        className="lg:grid px-8 space-y-4 flex flex-col-reverse lg:space-y-0 grid-cols-2 mt-20 py-20 gap-10 max-w-7xl items-center mx-auto"
      >
        <img
          src="/assets/Picture1.png"
          alt="/assets/Picture1.png"
          className="w-full object-cover h-[600px]"
        />

        <div className="space-y-8">
          <h4 className="text-5xl">Our Mission</h4>

          <p className="text-lg">
            To promote personal development by helping our clients to engage
            into activities that will empower them and help them build their
            careers and respective communities.
          </p>
          <p className="text-lg">
            Everyone has opportunities to be successful, we therefore aim to
            help individuals see the opportunity they have and how to take
            advantage of them through our diverse and person-specific services..{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
