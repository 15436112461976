import { Form } from 'antd'
import Input from 'components/base/Input'
import Select from 'components/base/Select'
import React from 'react'

export default function UserInfo() {
    const genderOptions = [
        {label: "Male", value: "M"},
        {label: "Female", value: "F"},
        {label: "Rather not say", value: "O"},
    ]
    

  return (
    <div>
        <div className='lg:flex lg:space-x-8'>
        <Form.Item rules={[
                {required: true, message: "This field is required"}
              ]} label="First Name" className='flex-1' name="firstName">
            <Input />
        </Form.Item>
        <Form.Item rules={[
                {required: true, message: "This field is required"}
              ]} label="Last Name" className='flex-1' name="lastName">
            <Input />
        </Form.Item>
        </div>
        <Form.Item rules={[
                {required: true, message: "This field is required"}
              ]} label="Address" name="address">
            <Input />
        </Form.Item>
        <div className='lg:grid lg:grid-cols-2 gap-8 gap-y-0 '>
        <Form.Item rules={[
                {required: true, message: "This field is required"}
              ]} label="Email" name="emailAddress">
            <Input />
        </Form.Item>
        <Form.Item rules={[
                {required: true, message: "This field is required"}
              ]} label="Phone" name="phoneNumber">
            <Input />
        </Form.Item>
        <Form.Item rules={[
                {required: true, message: "This field is required"}
              ]} label="Date Of Birth" name="dateOfBirth">
            <Input type="date" />
        </Form.Item>
        <Form.Item rules={[
                {required: true, message: "This field is required"}
              ]} label="Gender" name="gender">
            <Select options={genderOptions} placeholder="Choose your gender" />
        </Form.Item>
        <Form.Item label="Username" name="username">
            <Input />
        </Form.Item>
        </div>
    </div>
  )
}
