import Logo from "components/Logo";
import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import {
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
  FaLinkedinIn,
  FaPhoneVolume,
  FaTwitter,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa";

export default function PageFooter() {
  return (
    <div>
      <footer className="flex flex-col justify-center items-center max-w-9xl  mx-auto bg-white p-10 xl:px-48">
        <div className="flex flex-col items-center">
          <Logo className="w-16" />
          <p className="font-light mt-4">
            Hardwork, Service, Loyalty, and Patience
          </p>
        </div>
        <div>
          <div className="flex gap-4">
            <div className="w-10 h-10 border bg-blue-500 rounded-full flex items-center justify-center">
              <a
                href="https://www.facebook.com/innovationpivot/"
                className="text-white hover:text-white"
                target="_blank"
              >
                <FaFacebookF />
              </a>
            </div>
            <div className="w-10 h-10 border bg-green-500 rounded-full flex items-center justify-center">
              <a target="_blank" href="https://wa.me/c/237678018051" className="text-white hover:text-white">
                <FaWhatsapp />
              </a>
            </div>
            <div className="w-10 h-10 border bg-blue-400 rounded-full flex items-center justify-center">
              <a target="_blank" href="https://twitter.com/LtdGip" className="text-white hover:text-white">
                <FaTwitter />
              </a>
            </div>
            <div className="w-10 h-10 border bg-red-400 rounded-full flex items-center justify-center">
              <a href="https://www.instagram.com/giplted"  target="_blank" className="text-white hover:text-white"> 
                <FaInstagram />
              </a>
            </div>
            <div className="w-10 h-10 border bg-black rounded-full flex items-center justify-center">
              <a href="https://www.linkedin.com/in/gip-ltd-94837b24b" target="_blank" className="text-white hover:text-white">
                <FaLinkedinIn />
              </a>
            </div>
          </div>
          {/* <div className="flex items-center">
            <a className="flex items-center gap-4 text-black" href={`tel:237678018051`}>
              <FaPhoneVolume /> <span>+237 678018051</span>
            </a>
            <a className="flex items-center gap-4 text-black" href={`mailto:ipeagle@gmail.com`}>
              <AiOutlineMail /> <span>gipeagle@gmail.com</span>
            </a>
          </div> */}
        </div>
      </footer>
      <div className="lg:max-w-8xl flex text-white justify-between mx-auto gap-8 bg-black p-10 xl:px-48 flex-col-reverse items-center lg:flex-row">
        <p>©2022 GIP. All rights reserved</p>

        <ul className="flex gap-10 text-sm flex-wrap">
          <li >
            <a href="tel:237678018051" className="flex items-center gap-2 text-white hover:text-white">              
            <div className="flex justify-center items-center w-8 h-8 rounded-full bg-green-600">
              <FaPhone />
            </div>
            <span>237 678018051</span>
            </a>
          </li>
          <li >
            <a href="mailto:gipeagle@gmail.com" className="flex items-center gap-2 text-white hover:text-white">              
            <div className="flex justify-center items-center w-8 h-8 rounded-full bg-green-600">
              <FaEnvelope />
            </div>
            <span>gipeagle@gmail.com</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
