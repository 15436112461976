import React from 'react'
import AntSelect from "antd/lib/select";
import classNames from 'classnames';

export default function Select({options, value, className, ...rest}) {
    const cn = classNames({
        [className]: true,
        'w-full': true
    })
  return (
    <AntSelect options={options} value={value} className={cn} size="large" {...rest} />
  )
}
