import {useState} from "react";
import Button from "components/base/Button";
import Form from "antd/lib/form";
import Input from "components/base/Input";
import AuthWrapper from "components/AuthWrapper";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import apiClient from "apiClient";

export default function ChangePasswordPage() {
  const [form] = Form.useForm();
  const params = useParams();

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function resetPassword(data) {
    delete data.confirm_password;
    console.log(data, params.token)
    setLoading(true)
    try {
      let res = await apiClient({
        method: "post",
        url: "/reset-password",
        data: {...data, token: params.token}
      });
      if (res.data.success) {
        setMessage("Your password has been changed successfully");
      } else {
        setError(res.data.reason);
      }
      setLoading(false)
    } catch {
      setError("An error occured");
      setLoading(false)
    }
  }

  return (
      <AuthWrapper>
        <div>
          <h1 className="font-semibold text-2xl font-display">
            Change Password
          </h1>
          <p>Enter your Login Password</p>
        </div>
        {error && <Alert message={error} type="error" />}
      {message && <Alert message={message} type="success" />}
        <Form form={form} name="login" onFinish={resetPassword} layout="vertical" autoComplete="off">
          <Form.Item
            label="New Password"
            name="password"
            requiredMark="optional"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Password is required!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            requiredMark="optional"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
  
                  return Promise.reject(
                    new Error("The two passwords that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button loading={loading} htmlType="submit" className="w-full">
            Change Password
          </Button>
        </Form>
      </AuthWrapper>
  );
}
