import { DatePicker, Form } from "antd";
import Input from "components/base/Input";
import React from "react";

export default function Education() {
  return (
    <div>
      <div className="lg:grid lg:grid-cols-2 gap-8 gap-y-0 ">
        <Form.Item label="School" name={["education", "school"]}>
          <Input />
        </Form.Item>
        <Form.Item label="Certificate" name={["education", "certificate"]}>
          <Input />
        </Form.Item>
        <Form.Item label="Year" name={["education", "year"]}>
          <DatePicker picker="year" size="large" className="w-full" />
        </Form.Item>
      </div>
    </div>
  );
}
