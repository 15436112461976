import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import SignUpPage from "../pages/SignUpPage";
import CreatePasswordPage from "../pages/CreatePasswordPage";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import ForgetPasswordPage from "../pages/ForgetPasswordPage";
import AccountInActive from "pages/AccountInActive";
import EmailNotVerified from "pages/EmailNotVerified";
import AccountCreated from "pages/AccountCreated";
import VerifyEmail from "pages/VerifyEmail";
import ContactUsPage from "pages/ContactUsPage";


const AuthRoutes =  {
  ContactUs: {
    path: "/auth/contact-us",
    component: ContactUsPage
  },
  LoginPage: {
    path: "/auth/login",
    component: LoginPage
  },
  SignUpPage: {
    path: "/auth/register",
    component: SignUpPage
  },
  CreatePasswordPage: {
    path: "/auth/create-password",
    component: CreatePasswordPage
  },
  ChangePasswordPage: {
    path: "/auth/change-password/:token",
    component: ChangePasswordPage
  },
  ForgetPasswordPage: {
    path: "/auth/forget-password",
    component: ForgetPasswordPage
  },
  EmailNotVerified: {
    path: "/auth/email-not-verified",
    component: EmailNotVerified
  },
  VerifyEmail: {
    path: "/auth/email-verify/:token",
    component: VerifyEmail
  },
  AccountInActive: {
    path: "/auth/account-inactive",
    component: AccountInActive
  },
  AccountCreated: {
    path: "/auth/account-created",
    component: AccountCreated
  },
  HomePage: {
    path: "/",
    component: HomePage
  },
};

export default AuthRoutes;