import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { FiPower } from "react-icons/fi";
import Link from "components/base/Link";
import Logo from "components/Logo";
import { AnimatePresence, motion } from "framer-motion";
import { fadeIn } from "animations";
import { useAuth } from "@context";
import AdminRoutes from "@routes";

export default function NavBar() {
  const [show, setShow] = useState(false);
  const auth = useAuth().auth;

  const toggleShow = () => setShow(!show);
  let menus = [
    { path: AdminRoutes.DashboardPage.path, name: "Overview" },
    // { path: AdminRoutes.AppointmentPage.path, name: "Appointments" },
  ];

  return (
    <div className="sticky  top-0 z-10">
      <div className="flex gap-10 xl:px-40 px-8 h-16 items-center shadow justify-between bg-[#f3f0f0] ">
        <div className="font-display uppercase font-bold text-lg">
          <Logo path={AdminRoutes.DashboardPage.path} />
        </div>

        <section className="flex items-center divide-x divide-black/50 space-x-8">
          <div className="xl:flex items-center gap-8 hidden">
            {menus.map((menu, i) => {
              return (
                <Link.NavLink
                  className="!text-gray-600 hover:text-black"
                  key={i}
                  to={menu.path}
                >
                  {menu.name}
                </Link.NavLink>
              );
            })}
          </div>
          <div className="flex gap-4 items-center">
            <div className="flex divide-x divide-black/50 space-x-4">
              <Link to={AdminRoutes.ProfileSettings.path}>
                {auth.user.name ? <div className="flex items-center space-x-2 px-4 cursor-pointer">
                  <div className="w-10 h-10 flex justify-center items-center uppercase text-2xl bg-black text-white rounded-full">
                    {auth?.user.name?.charAt(0)}
                  </div>
                  <div className="text-black font-bold">{auth?.user.name}</div>
                </div> : <span className="pl-4">My Profile</span>}
              </Link>

              <button
                className="flex items-center pl-4 text-red-600 space-x-1 text-2xl"
                onClick={auth.logoutUser}
              >
                <FiPower />
              </button>
            </div>
            <button className="xl:hidden text-black" onClick={toggleShow}>
              <FaBars />
            </button>
          </div>
        </section>
      </div>

      {show && (
        <AnimatePresence>
          <motion.section
            initial={fadeIn.hidden}
            animate={fadeIn.visible}
            className="h-screen inset-0 w-full shadow absolute p-4"
          >
            <img
              src="/assets/banner.jpg"
              alt="banner image"
              className="absolute inset-0 h-full w-full object-cover bg-center"
            />
            <div className="w-full h-full absolute inset-0 bg-primary/70" />

            <div className="flex justify-end relative">
              <button onClick={toggleShow} className="text-white text-2xl">
                <FaTimes />
              </button>
            </div>

            <ul className="mt-10 space-y-4 relative">
              {menus.map((menu, i) => {
                return (
                  <li key={i}>
                    <Link.NavLink
                      onClick={toggleShow}
                      className="!text-white/80"
                      to={menu.path}
                    >
                      {menu.name}
                    </Link.NavLink>
                  </li>
                );
              })}
            </ul>
          </motion.section>
        </AnimatePresence>
      )}
    </div>
  );
}
