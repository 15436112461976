import axios from "axios";
import { encryptData } from "utils/encryption";
const token = localStorage.getItem("token");
const apiClient = async ({ method, url, data, params = {} }) => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  let headers = {};
  if (token !== "null") {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  headers = {
    ...headers,
    "X-Time": encryptData(Date.parse(new Date()).toString()),
  };
  axios.defaults.headers = headers;

  // const payload = encryptData(
  //   JSON.stringify({
  //     data,
  //   })
  // );

  try {
    let res = await axios({
      // data: { payload },
      url,
      method,
      params,
      data
    });
    return Promise.resolve(res);
  } catch (error) {
    console.log(error);
    return Promise.reject();
  }
};

export default apiClient;
