import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AdminRoutes from "routes";
import AuthRoutes from "routes/auth_routes";
import Layout from "components/layout";
import AdminLayout from "components/AdminLayout";

export default function Pages() {
  return (
    <Router>
      <Switch>
        <Route path="/admin">
          <Switch>
            {Object.values(AdminRoutes).map((route) => {
              return (
                <Route
                  key={route.path}
                  render={() => {
                    let Component = route.component;
                    return (
                      <AdminLayout>
                        <Component />
                      </AdminLayout>
                    );
                  }}
                  path={route.path}
                  exact
                />
              );
            })}
          </Switch>
        </Route>

        {Object.values(AuthRoutes).map((route) => (
          <Route
            key={route.path}
            render={() => {
              let Component = route.component;
              return (
                <Layout>
                  <Component />
                </Layout>
              );
            }}
            path={route.path}
            exact
          />
        ))}
      </Switch>
    </Router>
  );
}
