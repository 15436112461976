import { Form, Modal, Spin } from "antd";
import apiClient from "apiClient";
import classNames from "classnames";
import Button from "components/base/Button";
import AdditionalInfo from "components/ProfileTabs/AdditionalInfo";
import Education from "components/ProfileTabs/Education";
import UserInfo from "components/ProfileTabs/UserInfo";
import { useQuery } from "hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const tabs = {
  UserInfo: {
    component: UserInfo,
    name: "Profile Information",
  },
  Education: {
    component: Education,
    name: "Education Information",
  },
  AdditionalInfo: {
    component: AdditionalInfo,
    name: "Additional Information",
  },
};

export default function ProfileSettings() {
  const history = useHistory();
  const query = useQuery();
  const [form] = Form.useForm();
  const [currentTab, setCurrentTab] = useState(
    query.get("tab") || Object.keys(tabs)[0]
  );

  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  let current = tabs[currentTab];

  function switchTabs(tab) {
    history.push(window.location.pathname + `?tab=${tab}`);
    setCurrentTab(tab);
  }

  const linkclassName = (isActive) =>
    classNames({
      "font-semibold text-base cursor-pointer border border-transparent p-2": true,
      "!border-gray-400 rounded-full bg-gray-50": isActive,
    });

  async function handleSubmit(data) {
    setSaving(true);
    try {
        let res = await apiClient({ method: "post", url: "/profile", data});
        setSaving(false)
        if (res.data.success) {
            Modal.success({title: "Saved"})
        } else {
          Modal.error({ title: "An error occured" });
        }
      } catch (error) {
        console.log(error);
        setSaving(false)
        Modal.error({ title: "An error occured" });
      }
  }

  const fetchProfile = async () => {
    setLoading(true)
    try {
      let res = await apiClient({ method: "get", url: "/profile" });
      setLoading(false)
      if (res.data.success) {
        let data = res.data.data;
        let education = data.education || {};
        if (education.year) {
            education.year = moment(new Date(education.year));
        }

        let info = data.info ?? '{}';

        setProfileData({...data, info: JSON.parse(info), education, additionalQuestions:  res.data.additionalQuestions});
      } else {
        Modal.error({ title: "An error occured" });
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
      Modal.error({ title: "An error occured" });
    }
  };

  useEffect(() => {
    let isSubscribed = false;
    if (!isSubscribed) {
        fetchProfile();
    }

    return () => {
      isSubscribed = true;
    };
  }, []);

  return (
    <div>
      <div className="w-screen max-w-5xl mx-auto mt-10 space-y-2  px-4">
        <div className="">
          <h4 className="font-display text-3xl">Settings</h4>
          <div className="flex justify-end w-full">
            <Button
              onClick={() => {
                form.submit();
              }}
              loading={saving}
            >
              Save
            </Button>
          </div>
        </div>
        <div className="lg:flex lg:space-x-20">
          <div className="space-y-10">
            <ul className="space-y-2">
              {Object.keys(tabs).map((tab) => (
                <li
                  key={tab}
                  onClick={() => {
                    switchTabs(tab);
                  }}
                  className={linkclassName(currentTab === tab)}
                >
                  {tabs[tab].name}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-1">
            <h4 className="font-display text-4xl">{current.name}</h4>
            {loading ? <div className="w-full h-20 flex justify-center items-center">
                <Spin />
            </div> :<Form layout="vertical" onFinish={handleSubmit} form={form} initialValues={profileData}>
              {Object.keys(tabs).map((tab) => {
                const Component = tabs[tab].component;
                let className = classNames({
                  hidden: tab !== currentTab,
                });
                return (
                  <div className={className} key={tab}>
                    <Component additionalQuestions={profileData.additionalQuestions}  />
                  </div>
                );
              })}
            </Form>}
          </div>
        </div>
      </div>
    </div>
  );
}
