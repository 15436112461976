import React from "react";
import { Helmet } from "react-helmet";

export default function Head({
  title = "",
  description = "GIP is a company with well-trained personnel’s in different areas of life with the aim of helping many attain success in their academic, career, business, and other areas of their lives through education, career, and business counselling and assistances.",
}) {
  return (
    <Helmet>
      <title>
        GIP {title && "-"} {title}
      </title>
      <meta name="description" content={description} />
    </Helmet>
  );
}
