import apiClient from "apiClient";
import React, { useReducer } from "react";
import { createContext, useContext } from "react";
import { decryptData } from "utils/encryption";
import AdminRoutes from "routes";
import AuthRoutes from "routes/auth_routes";
function loginClient(user) {
  return apiClient({
    method: "post",
    url: "/login",
    data: user,
  });
}

const authContext = createContext();

export function AuthContextProvider({ children }) {
  const context = {
    auth: useAuthContext(),
    info: useInfoContent(),
  };
  return (
    <authContext.Provider value={context}>{children}</authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useAuthContext() {
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("name");
  // console.log(name)
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [auth, setAuth] = useReducer(reducer, {
    user: { token, name },
    isAuthenticated: true,
    loading: false,
    error: null,
  });

  const loginUser = async (user) => {
    setAuth({ loading: true, user: {}, error: null });
    loginClient(user)
      .then((response) => {
        setAuth({ loading: false });
        if (response.status === 200 && response.data.success) {
          const { client, token } = response.data.data;

          const decrypted = JSON.parse(decryptData(client) || {});
          const {
            firstName = "",
            lastName = "",
            isClientEmailValidated,
            isActive,
          } = decrypted;
          const name = firstName + " " + lastName;
          console.log(firstName, lastName)
          if (token) {
            localStorage.setItem("token", token);
          }
          if (name !== "null null") {
            localStorage.setItem("name", name );
          }
          if (!isClientEmailValidated) {
            window.location.replace(AuthRoutes.EmailNotVerified.path);
          } else if (!isActive) {
            window.location.replace(AuthRoutes.AccountInActive.path);
          } else {
            setAuth({
              error: null,
              user: { name, token },
            });
            window.location.replace(AdminRoutes.DashboardPage.path);
          }
        } else {
          setAuth({
            error: { message: response.data.message },
            isAuthenticated: false,
          });
        }
      })
      .catch(() => {
        setAuth({ loading: false });
      });
  };

  const logoutUser = () => {
    localStorage.clear();
    setAuth({ user: null, isAuthenticated: false, error: null });
    window.location.replace(AuthRoutes.LoginPage.path);
  };
  return { ...auth, loginUser, logoutUser };
}

function useInfoContent() {
  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, setData] = useReducer(reducer, {});

  const getInfo = async () => {
    try {
      let response = await apiClient({ method: "get", url: "info" });
      if (response.data.success) {
        setData({ data: response.data.data });
      }
    } catch (error) {}
  };

//   if (window.location.pathname.includes("admin")) {
//     getInfo();
//   }

  return { ...state };
}
