import React, { useReducer } from "react";
import Form from "antd/lib/form";
import AuthRoutes from "routes/auth_routes";
import Input from "components/base/Input";
import { Link } from "react-router-dom";
import Button from "components/base/Button";
import AuthWrapper from "components/AuthWrapper";
import { useAuth } from "@context";
import { Alert, Result } from "antd";
import apiClient from "apiClient";

export default function ContactUsPage() {
  const auth = useAuth().auth;

  const reducer = (prevState, action) => ({ ...prevState, ...action });
  const [state, dispatch] = useReducer(reducer, {});
  const contactUs = async (data) => {
    console.log(data);
    try {
      dispatch({ loading: true });
      let res = await apiClient({ method: "post", url: "/contact-us", data });
      dispatch({ loading: false });
      if (res.data.success) {
        dispatch({ success: true });
      } else {
        dispatch({ success: false });
      }
      console.log(res);
    } catch (error) {
      dispatch({ loading: false });
      console.log(error);
    }
  };
  return (
    <AuthWrapper>
      <div>
        <h1 className="font-semibold text-3xl font-display">
          A Quick Question for us?
        </h1>
        <p>Enter your email and your question</p>
      </div>
      {(state.success === false) && <Alert message="An error occured" type="error" />}

      {state.success && (
        <Result
          title="Thanks for contacting Us"
          status="success"
          subTitle="Thanks for contacting us. We will get back to you shortly"
        />
      )}
      {!state.success && (
        <Form name="login" layout="vertical" onFinish={contactUs}>
          <Form.Item
            label="Email Address"
            name="emailAddress"
            requiredMark="optional"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Question"
            name="question"
            type="textarea"
            requiredMark="optional"
            rules={[
              {
                required: true,
                message: "Please enter your question!",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button
              loading={state.loading}
              htmlType="submit"
              className="w-full"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </AuthWrapper>
  );
}
