import React, { useState } from "react";
import Button from "@base/Button";
import Form from "antd/lib/form";
import Input from "@base/Input";
import AuthRoutes from "routes/auth_routes";
import AuthWrapper from "components/AuthWrapper";
import { Alert } from "antd";
import apiClient from "apiClient";
import { useHistory } from "react-router-dom";

export default function LoginPage() {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const history = useHistory();

  async function createUser(data) {
    delete data.confirm_password;
    try {
      let res = await apiClient({ method: "post", url: "/register", data });
      if (res.data.success) {
        history.push(AuthRoutes.AccountCreated.path);
      } else {
        setError(res.data.reason);
      }
    } catch {
      setError("An error occured");
    }
  }
  return (
    <AuthWrapper img="/assets/Picture1.png">
      <div>
        <h1 className="font-semibold text-3xl font-display">Create account</h1>
        <p>Enter your email to create your account</p>
      </div>
      {error && <Alert message={error} type="error" />}
      <Form
        name="login"
        form={form}
        onFinish={createUser}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="emailAddress"
          requiredMark="optional"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: "email",
              message: "Invalid Email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          requiredMark="optional"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          requiredMark="optional"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" className="w-full">
            Create Account
          </Button>
        </Form.Item>

        <Button
          href={AuthRoutes.LoginPage.path}
          type="default"
          className="w-full"
        >
          Login
        </Button>
      </Form>
    </AuthWrapper>
  );
}
