import AuthWrapper from 'components/AuthWrapper'
import Button from 'components/base/Button'
import React from 'react'
import AuthRoutes from 'routes/auth_routes'

export default function EmailNotVerified() {
  return (
    <AuthWrapper>
        <div>
        <h2 className='font-display text-2xl font-bold'>Email not verified</h2>
        <p>Your email has not been verified. An email was sent to your mail. Follow the instructions to verify your email</p>
        <Button href={AuthRoutes.LoginPage.path}>Go to login</Button>
        </div>
    </AuthWrapper>
  )
}
