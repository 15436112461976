import React from "react";

export default function Footer() {
  let year = new Date().getFullYear()
  return (
      <div className="w-screen flex text-white justify-center mx-auto gap-8 py-2 fixed bottom-0 bg-black xl:px-48">
        <span>©{year} GIP. All rights reserved</span>
        {/* <ul className="flex gap-10 text-sm m-0">
          <li className="underline">Privacy Policy</li>
          <li className="underline">Cookie Policy</li>
        </ul> */}
      </div>
  );
}
