import { Form } from "antd";
import React from "react";
import Input from "components/base/Input";

export default function AdditionalInfo({ additionalQuestions = "[]" }) {
  let data = JSON.parse(additionalQuestions);
  return (
    <div>
      <div className="lg:grid lg:grid-cols-2 gap-8 gap-y-0 ">
        {data?.map((question, i) => {
          return (
            <Form.Item
              label={question}
              name={["info", `Q${i}`]}
              className="!mb-0"
              rules={[
                {required: true, message: "This field is required"}
              ]}
            >
              <Input />
            </Form.Item>
          );
        })}
      </div>
    </div>
  );
}
