import { useState } from "react";
import Button from "@base/Button";
import Form from "antd/lib/form";
import Input from "@base/Input";
import AuthWrapper from "components/AuthWrapper";
import { Alert } from "antd";
import apiClient from "apiClient";

export default function ForgetPasswordPage() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function sendResetEmail(data) {
    setLoading(true)
    try {
      let res = await apiClient({
        method: "post",
        url: "/send-reset-email",
        data
      });
      if (res.data.success) {
        setMessage("An email has been sent to your email address. Follow the instructions to reset your password");
      } else {
        setError(res.data.reason);
      }
      setLoading(false)
    } catch {
      setError("An error occured");
      setLoading(false)
    }
  }

  return (
    <AuthWrapper>
      <div>
        <h1 className="font-semibold text-2xl font-display">Reset Password</h1>
        <p>Enter your email to reset your Password</p>
      </div>
      {error && <Alert message={error} type="error" />}
      {message && <Alert message={message} type="success" />}
      <Form
        name="login"
        onFinish={sendResetEmail}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="emailAddress"
          requiredMark="optional"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: "email",
              message: "Invalid Email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Button htmlType="submit" loading={loading} className="w-full">
          Reset Password
        </Button>
      </Form>
    </AuthWrapper>
  );
}
