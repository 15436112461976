import React from "react";
import { Link as LinkWrapper } from "react-router-dom";
import classnames from "classnames";

export default function Link({ to = "", className, children }) {
  let cn = classnames({
    [className]: true,
    "text-primary": true,
  });
  return <LinkWrapper className={cn} to={to} children={children} />;
}

Link.NavLink = ({ children, to = "#", className="", onClick= () => {}}) => {
  const cn = classnames({
    "after:contents after:w-10 after:h-1 font-semibold  hover:after:bg-secondary text-black relative after:-bottom-2 after:left-1/2 transform after:-translate-x-1/2 after:absolute": true,
    [className]: true,
    '!text-primary after:!bg-secondary': window.location.pathname.includes(to)
  })
  return (
    <a
      className={cn}
      href={to}
      onClick={onClick}
    >
      {children}
    </a>
  );
};
