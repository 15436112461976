import AppointmentPage from "pages/admin/AppointmentPage";
import ConsultAppointment from "pages/admin/ConsultAppointment";
import CreateAppointment from "pages/admin/CreateAppointment";
import DashboardPage from "pages/admin/DashboardPage";
import ProfileSettings from "pages/admin/ProfileSettings";

const AdminRoutes = {
    DashboardPage: {
        path: "/admin/overview",
        component: AppointmentPage
      },
    AppointmentPage: {
        path: "/admin/appointment",
        component: AppointmentPage
      },
    CreateAppointment: {
        path: "/admin/appointment/create",
        component: CreateAppointment
      },
    ProfileSettings: {
        path: "/admin/profile",
        component: ProfileSettings
      },
    ConsultAppointment: {
        path: "/admin/appointment/:id",
        routePath: "/admin/appointment",
        component: ConsultAppointment
      }
};

export default AdminRoutes;